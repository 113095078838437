import { ContentConfigurationStatementT, StaticContentT } from '../../../../types/StaticContent.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { saveContentStatement } from '../../service/StaticContentService.ts';
import { BasicContentTypeForm, BasicContentTypeFormValues } from '../BasicContentTypeForm/BasicContentTypeForm.tsx';
import { CALL_TO_ACTION_ITEMS_MAX_LENGTH } from '../../../../types/Constants.ts';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentStatement({ presentationId, content }: Props) {
	const conf = content.configuration as ContentConfigurationStatementT;
	const updateStaticContent = useUpdateStaticContent(presentationId, content.id, (data: ContentConfigurationStatementT) => {
		return saveContentStatement(presentationId, content.id, data);
	});

	const onSubmit = async (data: BasicContentTypeFormValues) => {
		updateStaticContent.mutate({
			statement: data.text,
		});
	};

	return (
		<>
			<BasicContentTypeForm
				presentationId={presentationId}
				content={content}
				textField={{
					label: 'Insert your statement',
					placeholder: '',
					required: true,
					value: conf.statement,
					maxLength: CALL_TO_ACTION_ITEMS_MAX_LENGTH,
				}}
				onSubmit={onSubmit}
			/>
		</>
	);
}
