import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Icon, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { InteractionT, NumberInteractionConfigurationT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { saveNumberInteraction } from '../../service/InteractionService.ts';
import { AUTO_SAVE_DEBOUNCE_TIME, PLACE_HOLDER_MAX_LENGTH } from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const NumberInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateNumberInteraction = useUpdateInteraction(presentationId, interaction.id, (data: NumberInteractionConfigurationT) =>
		saveNumberInteraction(presentationId, interaction.id, data)
	);
	const conf = interaction.configuration as NumberInteractionConfigurationT;

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<NumberInteractionConfigurationT>({
		defaultValues: {
			minValue: conf.minValue,
			maxValue: conf.maxValue,
			placeholder: conf.placeholder,
			valueReference: conf.valueReference,
			required: interaction.required,
		},
	});

	const onSubmit: SubmitHandler<NumberInteractionConfigurationT> = async (data) => {
		updateNumberInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={interaction.id}
								elementType={ElementTypeT.INTERACTION}
								title={interaction.title}
								description={interaction.description}
							/>
							<FormControl mt={8}>
								<Input
									id='placeholder'
									fontSize={18}
									fontWeight='bold'
									size='lg'
									data-peer
									maxLength={PLACE_HOLDER_MAX_LENGTH}
									{...register('placeholder', {
										maxLength: PLACE_HOLDER_MAX_LENGTH,
									})}
								/>
								<FormLabel htmlFor='placeholder' variant='floating' size='lg'>
									Placeholder
								</FormLabel>
							</FormControl>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<FormControl mt={4}>
								<Text size='lg'>Accepted values</Text>
								<Flex alignItems='baseline'>
									<Flex pr={2}>
										<Text>MIN</Text>
									</Flex>
									<Flex>
										<FormControl mt={8} isInvalid={!!errors.minValue}>
											<Input
												w={24}
												id='minValue'
												type='number'
												placeholder='10'
												autoComplete='off'
												required
												min={0}
												{...register('minValue', {
													required: true,
													min: 0,
												})}
											/>
										</FormControl>
									</Flex>
									<Flex pr={2}>
										<Text>MAX</Text>
									</Flex>
									<FormControl mt={8} isInvalid={!!errors.maxValue}>
										<Input
											w={24}
											type='number'
											placeholder='30'
											autoComplete='off'
											required
											min={1}
											{...register('maxValue', {
												required: true,
												min: 1,
											})}
										/>
									</FormControl>
								</Flex>
							</FormControl>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
