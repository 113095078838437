import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, Icon, IconButton, Input, InputGroup, InputRightElement, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdAdd, MdClose } from 'react-icons/md';
import { InteractionT, MultipleChoiceConfigurationT } from '../../../../types/Interaction.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { useUpdateInteraction } from '../../hooks/useInteraction.ts';
import { saveMultipleChoiceInteraction } from '../../service/InteractionService.ts';
import {
	AUTO_SAVE_DEBOUNCE_TIME,
	MULTIPLE_CHOICE_OPTIONS_MAX_COUNT,
	MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
	MULTIPLE_CHOICE_OPTIONS_MIN_COUNT,
	MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
} from '../../../../types/Constants.ts';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	interaction: InteractionT;
}

export const MultipleChoiceInteraction: React.FC<Props> = ({ presentationId, interaction }) => {
	const conf = interaction.configuration as MultipleChoiceConfigurationT;
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateInteraction = useUpdateInteraction(presentationId, interaction.id, (data: MultipleChoiceConfigurationT) =>
		saveMultipleChoiceInteraction(presentationId, interaction.id, data)
	);

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<MultipleChoiceConfigurationT>({
		defaultValues: {
			multipleAnswer: conf.multipleAnswer,
			allowOthers: conf.allowOthers,
			options: conf.options,
			required: interaction.required,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		//@ts-expect-error: form lib
		name: 'options',
		rules: {
			minLength: MULTIPLE_CHOICE_OPTIONS_MIN_COUNT,
			maxLength: MULTIPLE_CHOICE_OPTIONS_MAX_COUNT,
			required: true,
		},
	});

	const onSubmit: SubmitHandler<MultipleChoiceConfigurationT> = async (data) => {
		updateInteraction.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={interaction} elementType={ElementTypeT.INTERACTION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm
								presentationId={presentationId}
								elementId={interaction.id}
								elementType={ElementTypeT.INTERACTION}
								title={interaction.title}
								description={interaction.description}
							/>
							<Flex flexDirection='column'>
								<FormControl mt={8}>
									<Text size='lg'>{translate('Options')}</Text>
									<Box mr={4}>
										{fields.map((field, index) => (
											<React.Fragment key={field.id}>
												<FormControl isInvalid={!!errors.options?.[index]}>
													<InputGroup size='lg' key={field.id} my={4}>
														<Input
															id={field.id}
															size='lg'
															placeholder=' '
															data-peer
															autoComplete='off'
															minLength={MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH}
															maxLength={MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH}
															{...register(`options.${index}` as const, {
																required: true,
																minLength: MULTIPLE_CHOICE_OPTIONS_MIN_LENGTH,
																maxLength: MULTIPLE_CHOICE_OPTIONS_MAX_LENGTH,
															})}
														/>
														<InputRightElement width='0.5rem' px={6} onClick={() => remove(index)}>
															<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
														</InputRightElement>
													</InputGroup>
												</FormControl>
											</React.Fragment>
										))}
									</Box>
									{fields.length < MULTIPLE_CHOICE_OPTIONS_MAX_COUNT ? (
										<Flex justifyContent='center'>
											<IconButton rounded='50' bg='brand' aria-label='' onClick={() => append('')} size='lg' w='8' icon={<Icon as={MdAdd} />} />
										</Flex>
									) : null}
								</FormControl>
							</Flex>
						</TabPanel>
						<TabPanel>
							<FormControl display='flex' alignItems='center'>
								<Switch size='lg' id='required' {...register('required')} />
								<FormLabel htmlFor='required' fontSize='lg' mx={2}>
									{translate('Answer Required')}
								</FormLabel>
							</FormControl>
							<Flex alignItems='center' mt={4}>
								<Switch size='lg' id='multipleAnswer' {...register('multipleAnswer')} />
								<FormLabel htmlFor='multipleAnswer' fontSize='lg' mx={2}>
									{translate('Multiple responses')}
								</FormLabel>
							</Flex>
							<Flex display='flex' alignItems='center' mt={4}>
								<Switch size='lg' id='allowOthers' {...register('allowOthers')} />
								<FormLabel htmlFor='allowOthers' fontSize='lg' mx={2}>
									{translate('Add Other')}
								</FormLabel>
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={interaction.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
