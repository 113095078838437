import { ConfigurationCallToActionItemT, ContentConfigurationCallToActionT, StaticContentT } from '../../../../types/StaticContent.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { saveContentCallToAction } from '../../service/StaticContentService.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import {
	AUTO_SAVE_DEBOUNCE_TIME,
	CALL_TO_ACTION_ITEMS_MAX_LENGTH,
	CALL_TO_ACTION_ITEMS_MIN_LENGTH,
	CALL_TO_ACTION_MAX_COUNT_ITEMS,
	CALL_TO_ACTION_MIN_COUNT_ITEMS,
} from '../../../../types/Constants.ts';
import { useEffect } from 'react';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, FormControl, FormLabel, Icon, IconButton, Input, InputGroup, InputRightElement, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { translate } from '../../../../translate';
import { IoSettingsSharp } from 'react-icons/io5';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import isEmpty from 'lodash/isEmpty';
import { MdAdd, MdClose } from 'react-icons/md';
import { isValidUrl, toValidUrl } from '../../../commons/utils/UrlValidationUtils.ts';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentCallToAction({ presentationId, content }: Props) {
	const conf = content.configuration as ContentConfigurationCallToActionT;
	const updateStaticContent = useUpdateStaticContent(presentationId, content.id, (data: ContentConfigurationCallToActionT) =>
		saveContentCallToAction(presentationId, content.id, data)
	);
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<ContentConfigurationCallToActionT>({
		defaultValues: {
			openInNewTab: conf.openInNewTab,
			items: conf.items,
		},
		shouldFocusError: false,
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'items',
		rules: {
			minLength: CALL_TO_ACTION_MIN_COUNT_ITEMS,
			maxLength: CALL_TO_ACTION_MAX_COUNT_ITEMS,
			required: true,
		},
	});

	const addEmptyItem = () => {
		const currentItems = watch('items');
		const existingEmptyItems = currentItems.filter((cat) => isEmpty(cat.label));
		if (existingEmptyItems.length === 0) {
			const item: ConfigurationCallToActionItemT = {
				label: '',
				reference: '',
			};
			append(item);
		}
	};

	useEffect(() => {
		if (conf.items.length === 0) {
			addEmptyItem();
		}
	}, []);

	const handleOnSubmit: SubmitHandler<ContentConfigurationCallToActionT> = async (data) => {
		const items = data.items.filter((item) => !isEmpty(item.label) && !isEmpty(item.reference));
		const parsedUrls = items.map((value) => {
			const result: ConfigurationCallToActionItemT = {
				label: value.label,
				reference: toValidUrl(value.reference),
			};
			return result;
		});
		updateStaticContent.mutate({
			...data,
			items: parsedUrls,
		});
	};

	const debouncedSubmit = debounce(handleSubmit(handleOnSubmit), AUTO_SAVE_DEBOUNCE_TIME);
	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={content} elementType={ElementTypeT.STATIC_CONTENT} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm presentationId={presentationId} elementId={content.id} elementType={ElementTypeT.STATIC_CONTENT} title={content.title} description={content.description} />
							<FormControl mt={8}>
								<Text size='lg'>{translate('Buttons')}</Text>
								<Box mr={4}>
									{fields.map((field, index) => {
										const hasLabelError = errors.items !== undefined && errors.items[index] ? !!errors.items[index].label : false;
										const hasReferenceError = errors.items !== undefined && errors.items[index] ? !!errors.items[index].reference : false;
										return (
											<Flex w='full' flexDirection='row' key={field.id} alignItems='center' gap={2}>
												<Flex w='100%' gap={8}>
													<FormControl isInvalid={hasLabelError}>
														<InputGroup size='lg' key={field.id} my={4}>
															<Input
																id={`${field.id}.label`}
																size='lg'
																placeholder='Label'
																data-peer
																minLength={CALL_TO_ACTION_ITEMS_MIN_LENGTH}
																maxLength={CALL_TO_ACTION_ITEMS_MAX_LENGTH}
																{...register(`items.${index}.label` as const, {
																	required: true,
																	minLength: CALL_TO_ACTION_ITEMS_MIN_LENGTH,
																	maxLength: CALL_TO_ACTION_ITEMS_MAX_LENGTH,
																})}
															/>
														</InputGroup>
													</FormControl>
													<FormControl isInvalid={hasReferenceError}>
														<InputGroup size='lg' key={field.id} my={4}>
															<Input
																id={`${field.id}.reference`}
																size='lg'
																placeholder='https://...'
																data-peer
																minLength={CALL_TO_ACTION_ITEMS_MIN_LENGTH}
																maxLength={CALL_TO_ACTION_ITEMS_MAX_LENGTH}
																required
																{...register(`items.${index}.reference` as const, {
																	required: true,
																	minLength: CALL_TO_ACTION_ITEMS_MIN_LENGTH,
																	maxLength: CALL_TO_ACTION_ITEMS_MAX_LENGTH,
																	validate: {
																		validUrl: (v) => isValidUrl(v),
																	},
																})}
															/>
															<InputRightElement width='0.5rem' px={6} onClick={() => remove(index)}>
																<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
															</InputRightElement>
														</InputGroup>
													</FormControl>
												</Flex>
											</Flex>
										);
									})}
								</Box>
								{fields.length < CALL_TO_ACTION_MAX_COUNT_ITEMS ? (
									<Flex justifyContent='center'>
										<IconButton rounded='50' bg='brand' aria-label='' onClick={addEmptyItem} size='lg' w='8' icon={<Icon as={MdAdd} />} />
									</Flex>
								) : null}
							</FormControl>
						</TabPanel>
						<TabPanel>
							<Flex flexDirection='row' alignItems='center'>
								<Switch size='lg' id='openInNewTab' {...register('openInNewTab')} />
								<FormLabel htmlFor='openInNewTab' fontSize='lg' mx={2}>
									{translate('Open links in new tab')}
								</FormLabel>
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={content.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
}
