import { ContentConfigurationListT, StaticContentT } from '../../../../types/StaticContent.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { saveContentList } from '../../service/StaticContentService.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME, LIST_ITEMS_MAX_LENGTH, LIST_ITEMS_MIN_LENGTH, LIST_MAX_COUNT_ITEMS, LIST_MIN_COUNT_ITEMS } from '../../../../types/Constants.ts';
import React, { useEffect } from 'react';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, FormControl, Icon, IconButton, Input, InputGroup, InputRightElement, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { translate } from '../../../../translate';
import { IoSettingsSharp } from 'react-icons/io5';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { MdAdd, MdClose } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentList({ presentationId, content }: Props) {
	const conf = content.configuration as ContentConfigurationListT;
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateStaticContent = useUpdateStaticContent(presentationId, content.id, async (data: ContentConfigurationListT) => saveContentList(presentationId, content.id, data));

	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
	} = useForm<ContentConfigurationListT>({
		defaultValues: {
			labels: conf.labels,
		},
	});

	const { fields, append, remove } = useFieldArray({
		control,
		//@ts-expect-error: form lib
		name: 'labels',
		rules: {
			minLength: LIST_MIN_COUNT_ITEMS,
			maxLength: LIST_MAX_COUNT_ITEMS,
			required: true,
		},
	});

	const addEmptyItem = () => {
		append('');
	};

	useEffect(() => {
		if (isEmpty(conf.labels)) {
			addEmptyItem();
		}
	}, []);

	const handleOnSubmit: SubmitHandler<ContentConfigurationListT> = async (data) => {
		updateStaticContent.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(handleOnSubmit), AUTO_SAVE_DEBOUNCE_TIME);
	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch, handleSubmit]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={content} elementType={ElementTypeT.STATIC_CONTENT} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm presentationId={presentationId} elementId={content.id} elementType={ElementTypeT.STATIC_CONTENT} title={content.title} description={content.description} />
							<Flex flexDirection='column' my={8}>
								<Text fontSize='xl'>Items</Text>
								<Box mr={4}>
									{fields.map((field, index) => (
										<React.Fragment key={field.id}>
											<FormControl isInvalid={!!errors.labels?.[index]}>
												<InputGroup size='lg' key={field.id} my={4}>
													<Input
														id={field.id}
														size='lg'
														placeholder=' '
														data-peer
														autoComplete='off'
														minLength={LIST_ITEMS_MIN_LENGTH}
														maxLength={LIST_ITEMS_MAX_LENGTH}
														{...register(`labels.${index}` as const, {
															required: true,
															minLength: LIST_ITEMS_MIN_LENGTH,
															maxLength: LIST_ITEMS_MAX_LENGTH,
														})}
													/>
													<InputRightElement width='0.5rem' px={6} onClick={() => remove(index)}>
														<IconButton rounded='50' aria-label='Delete' size='xs' icon={<Icon color='brand' as={MdClose} />} />
													</InputRightElement>
												</InputGroup>
											</FormControl>
										</React.Fragment>
									))}
								</Box>
								{fields.length < LIST_MAX_COUNT_ITEMS ? (
									<Flex justifyContent='center'>
										<IconButton rounded='50' bg='brand' aria-label='' onClick={addEmptyItem} size='lg' w='8' icon={<Icon as={MdAdd} />} />
									</Flex>
								) : null}
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={content.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
}
