import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { refreshElementPreview } from '../../presentation/components/ElementPreview/ElementPreview.tsx';
import { getStaticContentDetails } from '../service/StaticContentService.ts';

export const staticConentkey = (presentationId: string, staticContentId: string) => ['presentation', presentationId, 'static-content', staticContentId];

export const useStaticContent = (presentationId: string, staticContentId: string) => {
	return useQuery({
		queryKey: staticConentkey(presentationId, staticContentId),
		queryFn: () => getStaticContentDetails(presentationId, staticContentId),
		refetchOnWindowFocus: false,
	});
};

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateStaticContent = (presentationId: string, staticContentId: string, mutationFn: (visualization: any) => any) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: mutationFn,
		onSuccess: async () => {
			await queryClient.invalidateQueries({ queryKey: staticConentkey(presentationId, staticContentId) });
			refreshElementPreview();
		},
	});
};
