import { Box } from '@chakra-ui/react';
import React from 'react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const ComicVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<VisualizationInteractionCard interaction={visualization.interaction} />
			<TitleForm
				presentationId={presentationId}
				elementId={visualization.id}
				elementType={ElementTypeT.VISUALIZATION}
				title={visualization.title}
				description={visualization.description}
			/>
			<Box mt={4}>
				<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
			</Box>
		</>
	);
};
