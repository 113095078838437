import { StaticContentT } from '../../../../types/StaticContent.ts';
import { BasicContentTypeForm } from '../BasicContentTypeForm';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentCover({ presentationId, content }: Props) {
	const onSubmit = () => {};
	return (
		<>
			<BasicContentTypeForm presentationId={presentationId} content={content} onSubmit={onSubmit} />
		</>
	);
}
