import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { refreshElementPreview } from '../../presentation/components/ElementPreview/ElementPreview.tsx';
import { getVisualizationDetails } from '../service/VisualizationService.ts';

export const visualizationKey = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId];

export const useVisualization = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: visualizationKey(presentationId, visualizationId),
		queryFn: () => getVisualizationDetails(presentationId, visualizationId),
		refetchOnWindowFocus: false,
	});
};

/* eslint-disable */
export const useUpdateVisualization = (presentationId: string, visualizationId: string, mutationFn: (visualization: any) => any) => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: mutationFn,
		onSuccess: () => {
			void queryClient.invalidateQueries({ queryKey: visualizationKey(presentationId, visualizationId) });
			refreshElementPreview();
		},
	});
};
