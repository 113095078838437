import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, GridItem, Icon, Input, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { VisualizationT, WordCloudConfigurationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { saveWordCloudVisualization } from '../../service/VisualizationService.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME, MAX_SELECTED_ANSWER_REFERENCE_LENGTH } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { BiSolidPalette } from 'react-icons/bi';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { RadioButton, RadioButtonGroup } from '../../../commons/components/RadioButtonGroup/RadioButtonGroup.tsx';
import { TagSelector } from '../../../commons/components/TagSelector';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const WordCloudVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: WordCloudConfigurationT) =>
		saveWordCloudVisualization(presentationId, visualization.id, data)
	);
	const conf = visualization.configuration as WordCloudConfigurationT;

	const useFormMethods = useForm<WordCloudConfigurationT>({
		defaultValues: {
			reference: conf.reference,
			selectedAnswersActive: conf.selectedAnswersActive,
			selectedAnswers: conf.selectedAnswers,
			variant: conf.variant,
		},
	});

	const { register, handleSubmit, watch, control, setValue } = useFormMethods;

	const onSubmit: SubmitHandler<WordCloudConfigurationT> = (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			void debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			void invalidatePresentation();
		};
	}, [watch]);

	const handleOnChangeSelectedAnswerActive = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedAnswersActive = e.target.checked;
		if (!selectedAnswersActive) {
			setValue('selectedAnswers', []);
			setValue('reference', '');
		}
	};

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={BiSolidPalette} pr={2} />
							{translate('Design')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<VisualizationInteractionCard interaction={visualization.interaction} />
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
						</TabPanel>
						<TabPanel>
							<Flex flexDirection='column'>
								<Flex alignItems='center' mt={4}>
									<Switch
										id='selectedAnswersActive'
										size='lg'
										{...register('selectedAnswersActive', {
											onChange: handleOnChangeSelectedAnswerActive,
										})}
									/>
									<FormLabel htmlFor='selectedAnswersActive' fontSize='lg' mx={2}>
										{translate('Has selected answers')}
									</FormLabel>
								</Flex>

								{watch('selectedAnswersActive') ? (
									<>
										<GridItem colSpan={4} my={8}>
											<FormControl>
												<Input
													type='text'
													size='lg'
													placeholder=' '
													id='reference'
													data-peer
													maxLength={MAX_SELECTED_ANSWER_REFERENCE_LENGTH}
													{...register('reference', { maxLength: MAX_SELECTED_ANSWER_REFERENCE_LENGTH })}
												/>
												<FormLabel htmlFor='reference' variant='floating' size='lg'>
													Selected answers reference
												</FormLabel>
											</FormControl>
										</GridItem>
										<Controller
											name='selectedAnswers'
											control={control}
											rules={{ required: false, minLength: 0, maxLength: 5 }}
											render={({ field }) => (
												<GridItem colSpan={5}>
													<TagSelector
														name={field.name}
														onBlur={field.onBlur}
														onChange={field.onChange}
														value={field.value}
														minEntries={0}
														maxEntries={5}
														placeholder='Add an accepted entry'
													/>
												</GridItem>
											)}
										/>
									</>
								) : null}
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
						<TabPanel>
							<Flex flexDirection='column'>
								<Text mt={4} fontSize='lg'>
									Style
								</Text>
								<Box as='section' bg='bg-surface'>
									<Controller
										name='variant'
										control={control}
										rules={{ required: true }}
										render={({ field: { ...field } }) => (
											<RadioButtonGroup defaultValue='left' size='xl' {...field}>
												<RadioButton value='WORD'>Word</RadioButton>
												<RadioButton value='SENTENCE'>Sentence</RadioButton>
											</RadioButtonGroup>
										)}
									/>
								</Box>
							</Flex>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
