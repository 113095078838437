import React from 'react';
import { Box, Icon, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { VisualizationT } from '../../../../types/Visualization.ts';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { IoSettingsSharp } from 'react-icons/io5';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const NodesVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<Tabs variant='line' my={4}>
				<TabList>
					<Tab fontSize={20}>
						<Icon as={PiTextAlignJustifyFill} pr={2} />
						{translate('Content')}
					</Tab>
					<Tab fontSize={20}>
						<Icon as={IoSettingsSharp} pr={2} />
						{translate('Settings')}
					</Tab>
				</TabList>
				<TabPanels>
					<TabPanel>
						<VisualizationInteractionCard interaction={visualization.interaction} />
						<TitleForm
							presentationId={presentationId}
							elementId={visualization.id}
							elementType={ElementTypeT.VISUALIZATION}
							title={visualization.title}
							description={visualization.description}
						/>
					</TabPanel>
					<TabPanel>
						<Box mt={4}>
							<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
						</Box>
					</TabPanel>
				</TabPanels>
			</Tabs>
		</>
	);
};
