import ApiClient from '../../../apiClient';
import {
	BarChartConfigurationT,
	BasicCardsConfigurationT,
	BasicFlipCardsConfigurationT,
	PictogramConfigurationT,
	PieChartConfigurationT,
	SimpleMathConfigurationT,
	VisualizationCompatibility,
	VisualizationT,
	WheelConfigurationT,
	WordCloudConfigurationT,
} from '../../../types/Visualization.ts';

export const getVisualizationDetails = async (presentationId: string, visualizationId: string): Promise<VisualizationT> => {
	return (await ApiClient.getInstance().get<VisualizationT>(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}`)).data;
};

// ----

export const getVisualizationCompatibilityMap = async (): Promise<VisualizationCompatibility> => {
	return (await ApiClient.getInstance().get<VisualizationCompatibility>(`/author-app/presentations/any/visualizations/compatibiliy-map`)).data;
};

// ----

export const saveSimpleMathVisualization = async (presentationId: string, visualizationId: string, visualization: SimpleMathConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/simple-math`, visualization);
};

// ----

export const saveWordCloudVisualization = async (presentationId: string, visualizationId: string, visualization: WordCloudConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/word-cloud`, visualization);
};

// ----

export const saveWheelVisualization = async (presentationId: string, visualizationId: string, visualization: WheelConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/wheel`, visualization);
};

// ----

export const savePictogramVisualization = async (presentationId: string, visualizationId: string, visualization: PictogramConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/pictogram`, visualization);
};

// ----

export const saveBarChartVisualization = async (presentationId: string, visualizationId: string, visualization: BarChartConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/bar-chart`, visualization);
};

// ----

export const savePieChartVisualization = async (presentationId: string, visualizationId: string, visualization: PieChartConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/pie-chart`, visualization);
};

// ----
export const saveBasicCardsVisualization = async (presentationId: string, visualizationId: string, visualization: BasicCardsConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/basic-cards`, visualization);
};

// ----
export const saveBasicFlipCardsVisualization = async (presentationId: string, visualizationId: string, visualization: BasicFlipCardsConfigurationT): Promise<void> => {
	await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}/basic-flip-cards`, visualization);
};

// -----

export const deleteVisualization = async (presentationId: string, visualizationId: string): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/visualizations/${visualizationId}`);
};
