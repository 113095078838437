import React, { useState } from 'react';
import { InteractionT } from '../../../../types/Interaction.ts';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { Alert, AlertIcon, AlertTitle, Flex, IconButton, Tag, TagLabel, Tooltip } from '@chakra-ui/react';
import { useEntryList } from '../../hooks/useEntryList.ts';
import { Loading } from '../../../commons/components/Loading';
import { EntryListTable } from '../EntryListTable';
import { exportEntriesToCsv, SearchEntriesInput } from '../../service/EntryService.ts';
import { useNavigate } from 'react-router-dom';
import { EntryListHeader } from '../EntryListHeader';
import { EntryListPagination } from '../EntryListPagination';
import isEmpty from 'lodash/isEmpty';
import { DeleteInteractionEntries } from '../DeleteInteractionEntries';
import { NewElementHeader } from '../../../commons/components/NewElementHeader';
import { translate } from '../../../../translate';
import { MdDownload } from 'react-icons/md';

interface Props {
	presentationId: BasicPresentationT['id'];
	interactionId: InteractionT['id'];
}

export const EntryList: React.FC<Props> = ({ presentationId, interactionId }) => {
	const [exportingToCsv, setExportingToCsv] = useState(false);
	const [query, setQuery] = useState<SearchEntriesInput>({
		query: '',
		pageNumber: 1,
	});
	const { isPending, data, isError, refetch } = useEntryList(presentationId, interactionId, query);
	const navigate = useNavigate();

	const handleOnSearch = (q: string) => {
		setQuery((prevState) => {
			return {
				query: q.trim(),
				pageNumber: prevState.pageNumber,
			};
		});
	};

	const handleOnPageChange = (pageNumber: number) => {
		setQuery((prevState) => {
			return {
				query: prevState.query,
				pageNumber: pageNumber,
			};
		});
	};

	const handleExportToCsv = async () => {
		try {
			setExportingToCsv(true);
			await exportEntriesToCsv(presentationId, interactionId, `entries-${data?.interaction.title}.csv`);
		} finally {
			setExportingToCsv(false);
		}
	};

	if (isPending) {
		return <Loading />;
	}

	return (
		<Flex flexDirection='column' mx={8} gap={4}>
			{isError ? (
				<Alert status='error'>
					<AlertIcon />
					<AlertTitle>{`${translate('Error fetching')} entries`}</AlertTitle>
				</Alert>
			) : null}
			<Flex flexDirection='column' justifyContent='center' alignItems='center'>
				<NewElementHeader title={translate('Moderate answers')} onGoBack={() => navigate(-1)} />
				<Tag size='lg' backgroundColor='colorScheme.100' textColor='textColor'>
					{/*<Image src={getIconForElement(ElementTypeT.INTERACTION)(data!.interaction.type)} boxSize='8' alt={`${data!.interaction.type} Interaction`} />*/}
					<TagLabel fontSize='1.5rem' textColor='textColor'>
						{data!.interaction.title}
					</TagLabel>
				</Tag>
			</Flex>
			<Flex alignItems='center' justifyContent='flex-start' gap={4}>
				<Flex width='80%'>
					<EntryListHeader onInputChange={handleOnSearch} />
				</Flex>
				{!exportingToCsv ? (
					<Tooltip label='Export to csv'>
						<IconButton aria-label='export-csv' icon={<MdDownload />} onClick={handleExportToCsv} color='brand' />
					</Tooltip>
				) : (
					<Loading size='sm' />
				)}
				<DeleteInteractionEntries presentationId={presentationId} interactionId={interactionId} isDisabled={isPending || isEmpty(data?.entries)} onAfterDeletion={refetch} />
			</Flex>
			<Flex flexDirection='column'>
				<EntryListTable presentationId={presentationId} interactionId={interactionId} entry={data!} />
				<Flex alignItems='center' justifyContent='center' gap={4}>
					<EntryListPagination currentPage={query.pageNumber} canGoForward={!isEmpty(data?.entries)} onPageChange={handleOnPageChange} />
				</Flex>
			</Flex>
		</Flex>
	);
};
