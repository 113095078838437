import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { forwardRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import { INPUT_TITLE_MAX_LENGHT, INPUT_TITLE_MIN_LENGHT } from '../../../../types/Constants.ts';
import { translate } from '../../../../translate';

interface Props {
	title: string;
	placeholder?: string;
}

export type Ref = HTMLInputElement;

export const InputTitle = forwardRef<Ref, Props>(({ title, ...props }, ref) => {
	const isEmptyOrUntitled = isEmpty(title) || title === 'Untitled';
	const border = !isEmptyOrUntitled ? 'none' : undefined;
	return (
		<FormControl mt={8} isInvalid={isEmpty(title)}>
			<Input
				id='title'
				border={border}
				boxShadow={border}
				fontSize={22}
				fontWeight='bold'
				size='lg'
				data-peer
				maxLength={INPUT_TITLE_MAX_LENGHT}
				minLength={INPUT_TITLE_MIN_LENGHT}
				required
				{...props}
				ref={ref}
			/>
			<FormLabel htmlFor='title' variant='floating' size='lg'>
				{translate('Title')}
			</FormLabel>
		</FormControl>
	);
});

InputTitle.displayName = 'InputTitle';
