import React from 'react';
import { BasicElementT } from '../../../../types/Presentation.ts';
import { VisualizationTypeT } from '../../../../types/Visualization.ts';
import { SimpleMathVisualization } from '../SimpleMathVisualization';
import { RotatingWordsVisualization } from '../RotatingWordsVisualization';
import { useVisualization } from '../../hooks/useVisualization.ts';
import { Text } from '@chakra-ui/react';
import { WordCloudVisualization } from '../WordCloudVisualization';
import { BarChartVisualization } from '../BarChartVisualization';
import { PieChartVisualization } from '../PieChartVisualization';
import { WheelVisualization } from '../WheelVisualization';
import { NodesVisualization } from '../NodesVisualization';
import { GaugeVisualization } from '../GaugeVisualization';
import { RadarVisualization } from '../RadarVisualization';
import { BlocksVisualization } from '../BlocksVisualization';
import { ComicVisualization } from '../ComicVisualization';
import { PictogramVisualization } from '../PictogramVisualization';
import { Loading } from '../../../commons/components/Loading';
import { BasicCardsVisualization } from '../BasicCardsVisualization';
import { BasicFlipCardsVisualization } from '../BasicFlipCardsVisualization';
import { BoardVisualization } from '../BoardVisualization';
import { PillsVisualization } from '../PillsVisualization';

interface Props {
	presentationId: string;
	element: BasicElementT;
}

export const Visualization: React.FC<Props> = ({ presentationId, element }) => {
	const configurationType = element.type as VisualizationTypeT;
	const { isLoading, data } = useVisualization(presentationId, element.id);

	if (isLoading && !data) {
		return <Loading />;
	}

	if (!isLoading && !data) {
		return <Text>No data</Text>;
	}

	const visualization = data!;
	if (configurationType === VisualizationTypeT.SIMPLE_MATH) {
		return <SimpleMathVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.WORD_CLOUD) {
		return <WordCloudVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.BAR_CHART) {
		return <BarChartVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.PIE_CHART) {
		return <PieChartVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.ROTATING_WORDS) {
		return <RotatingWordsVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.COMIC) {
		return <ComicVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.NODES) {
		return <NodesVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.WHEEL) {
		return <WheelVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.BLOCKS) {
		return <BlocksVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.GAUGE) {
		return <GaugeVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.RADAR) {
		return <RadarVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.PICTOGRAM) {
		return <PictogramVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.BASIC_CARDS) {
		return <BasicCardsVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.BASIC_FLIP_CARDS) {
		return <BasicFlipCardsVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.BOARD) {
		return <BoardVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	} else if (configurationType === VisualizationTypeT.PILLS) {
		return <PillsVisualization presentationId={presentationId} visualization={visualization} key={visualization.id} />;
	}

	return null;
};
