import ApiClient from '../../../apiClient';
import { InteractionTypeT } from '../../../types/Interaction.ts';
import { VisualizationTypeT } from '../../../types/Visualization.ts';
import { StaticContentTypeT } from '../../../types/StaticContent.ts';
import { ElementTypeT } from '../../../types/Presentation.ts';

export interface UpdateElementInput {
	title: string;
	description?: string;
	elementType: ElementTypeT;
}

export const updateElement = async (presentationId: string, elementId: string, input: UpdateElementInput): Promise<void> => {
	return (await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/elements/${elementId}`, input)).data;
};

interface DuplicateElementOutput {
	newElementId: string;
	newSlideId: string;
}

export const duplicateElement = async (presentationId: string, elementId: string, elementType: ElementTypeT): Promise<DuplicateElementOutput> => {
	return (
		await ApiClient.getInstance().put(`/author-app/presentations/${presentationId}/elements/duplicate`, {
			elementId,
			elementType,
		})
	).data;
};

interface NewElementResult {
	slideId: number;
}

interface NewInteractionInput {
	title: string;
	type: InteractionTypeT;
}

export const createInteraction = async (presentationId: string, newInteraction: NewInteractionInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/interactions`, newInteraction)).data;
};

interface NewVisualizationInput {
	title: string;
	type: VisualizationTypeT;
	existingInteractionId?: string;
	newInteraction?: NewInteractionInput;
}

export const createVisualization = async (presentationId: string, newVisualization: NewVisualizationInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/visualizations`, newVisualization)).data;
};

//=====
interface CreateStaticContentInput {
	title: string;
	type: StaticContentTypeT;
}

export const createStaticContent = async (presentationId: string, input: CreateStaticContentInput): Promise<NewElementResult> => {
	return (await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/static-content`, input)).data;
};
