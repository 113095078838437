import { ContentConfigurationEmbedT, StaticContentT } from '../../../../types/StaticContent.ts';
import { useUpdateStaticContent } from '../../hooks/useStaticContent.ts';
import { saveContentEmbed } from '../../service/StaticContentService.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { SubmitHandler, useForm } from 'react-hook-form';
import debounce from 'lodash/debounce';
import { AUTO_SAVE_DEBOUNCE_TIME } from '../../../../types/Constants.ts';
import { useEffect } from 'react';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { Box, Flex, FormControl, Icon, Input, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { translate } from '../../../../translate';
import { IoSettingsSharp } from 'react-icons/io5';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { VALIDATION_URL_REGEX } from '../../../commons/utils/UrlValidationUtils.ts';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	content: StaticContentT;
}

export function StaticContentEmbed({ presentationId, content }: Props) {
	const conf = content.configuration as ContentConfigurationEmbedT;
	const updateStaticContent = useUpdateStaticContent(presentationId, content.id, (data: ContentConfigurationEmbedT) => {
		return saveContentEmbed(presentationId, content.id, data);
	});

	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<ContentConfigurationEmbedT>({
		defaultValues: {
			content: conf.content,
		},
	});

	const handleOnSubmit: SubmitHandler<ContentConfigurationEmbedT> = async (data) => {
		updateStaticContent.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(handleOnSubmit), AUTO_SAVE_DEBOUNCE_TIME);
	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={content} elementType={ElementTypeT.STATIC_CONTENT} />
			<form>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<TitleForm presentationId={presentationId} elementId={content.id} elementType={ElementTypeT.STATIC_CONTENT} title={content.title} description={content.description} />
							<Flex flexDirection='column' my={8}>
								<Text fontSize='xl'>Embed</Text>
								<FormControl isInvalid={!!errors.content} my={4}>
									<Input
										minLength={3}
										maxLength={50}
										size='lg'
										placeholder='https://...'
										required
										{...register(`content`, {
											required: true,
											minLength: 1,
											maxLength: 500,
											pattern: VALIDATION_URL_REGEX,
										})}
									/>
								</FormControl>
								{errors.content?.type === 'pattern' && <Text color='error'>{translate('Enter a url you want to embed')}</Text>}
							</Flex>
						</TabPanel>
						<TabPanel>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={content.slide} />
							</Box>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
}
