import React, { useEffect } from 'react';
import { Box, Flex, FormControl, FormLabel, GridItem, Icon, Input, Stack, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import { PieChartConfigurationT, VisualizationT } from '../../../../types/Visualization.ts';
import { useInvalidatePresentationQuery } from '../../../presentation/hooks/usePresentation.ts';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { savePieChartVisualization } from '../../service/VisualizationService.ts';
import { useUpdateVisualization } from '../../hooks/useVisualization.ts';
import { ElementTypeT } from '../../../../types/Presentation.ts';
import { VisualizationInteractionCard } from '../VisualizationInteractionCard';
import { AUTO_SAVE_DEBOUNCE_TIME, MAX_SELECTED_ANSWER_REFERENCE_LENGTH } from '../../../../types/Constants.ts';
import debounce from 'lodash/debounce';
import { ElementConfigurationTopBar } from '../../../commons/components/ElementConfigurationTopBar';
import { PiTextAlignJustifyFill } from 'react-icons/pi';
import { IoSettingsSharp } from 'react-icons/io5';
import { BiSolidPalette } from 'react-icons/bi';
import { SlideSettingsInput } from '../../../presentation/components/SlideSettingsInput';
import { RadioButtonGroup } from '../../../commons/components/RadioButtonGroup';
import { RadioButton } from '../../../commons/components/RadioButtonGroup/RadioButtonGroup.tsx';
import { TagSelector } from '../../../commons/components/TagSelector';
import { translate } from '../../../../translate';
import { TitleForm } from '../../../element/components/TitleForm';

interface Props {
	presentationId: string;
	visualization: VisualizationT;
}

export const PieChartVisualization: React.FC<Props> = ({ presentationId, visualization }) => {
	const invalidatePresentation = useInvalidatePresentationQuery(presentationId);
	const updateVisualization = useUpdateVisualization(presentationId, visualization.id, (data: PieChartConfigurationT) =>
		savePieChartVisualization(presentationId, visualization.id, data)
	);
	const conf = visualization.configuration as PieChartConfigurationT;

	const useFormMethods = useForm<PieChartConfigurationT>({
		defaultValues: {
			reference: conf.reference,
			selectedAnswersActive: conf.selectedAnswersActive,
			selectedAnswers: conf.selectedAnswers,
			variant: conf.variant,
			showFloatingLegend: conf.showFloatingLegend,
			showFooterLegend: conf.showFooterLegend,
		},
	});

	const { register, handleSubmit, watch, control, setValue } = useFormMethods;

	const onSubmit: SubmitHandler<PieChartConfigurationT> = async (data) => {
		updateVisualization.mutate(data);
	};

	const debouncedSubmit = debounce(handleSubmit(onSubmit), AUTO_SAVE_DEBOUNCE_TIME);

	useEffect(() => {
		const subscription = watch(() => {
			debouncedSubmit();
		});
		return () => {
			subscription.unsubscribe();
			invalidatePresentation();
		};
	}, [watch]);

	const handleOnChangeSelectedAnswerActive = (e: React.ChangeEvent<HTMLInputElement>) => {
		const selectedAnswersActive = e.target.checked;
		if (!selectedAnswersActive) {
			setValue('selectedAnswers', []);
			setValue('reference', '');
		}
	};

	return (
		<>
			<ElementConfigurationTopBar presentationId={presentationId} element={visualization} elementType={ElementTypeT.VISUALIZATION} />
			<form onSubmit={handleSubmit(onSubmit)}>
				<Tabs variant='line' my={4}>
					<TabList>
						<Tab fontSize={20}>
							<Icon as={PiTextAlignJustifyFill} pr={2} />
							{translate('Content')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={IoSettingsSharp} pr={2} />
							{translate('Settings')}
						</Tab>
						<Tab fontSize={20}>
							<Icon as={BiSolidPalette} pr={2} />
							{translate('Design')}
						</Tab>
					</TabList>
					<TabPanels>
						<TabPanel>
							<VisualizationInteractionCard interaction={visualization.interaction} />
							<TitleForm
								presentationId={presentationId}
								elementId={visualization.id}
								elementType={ElementTypeT.VISUALIZATION}
								title={visualization.title}
								description={visualization.description}
							/>
							<Flex flexDirection='column'>
								<GridItem colSpan={3}>
									<Flex alignItems='center'>
										<Switch
											id='selectedAnswersActive'
											size='lg'
											{...register('selectedAnswersActive', {
												onChange: handleOnChangeSelectedAnswerActive,
											})}
										/>
										<FormLabel htmlFor='selectedAnswersActive' fontSize='lg' mx={2}>
											{translate('Has selected answers')}
										</FormLabel>
									</Flex>
								</GridItem>
								{watch('selectedAnswersActive') ? (
									<>
										<GridItem>
											<FormControl mt='6'>
												<Input
													type='text'
													size='lg'
													placeholder=' '
													id='reference'
													data-peer
													maxLength={MAX_SELECTED_ANSWER_REFERENCE_LENGTH}
													{...register('reference', { maxLength: MAX_SELECTED_ANSWER_REFERENCE_LENGTH })}
												/>
												<FormLabel htmlFor='reference' variant='floating' size='lg'>
													{translate('Selected answers reference')}
												</FormLabel>
											</FormControl>
										</GridItem>
										<Controller
											name='selectedAnswers'
											control={control}
											rules={{ required: false, minLength: 0, maxLength: 5 }}
											render={({ field }) => (
												<GridItem>
													<TagSelector
														name={field.name}
														onBlur={field.onBlur}
														onChange={field.onChange}
														value={field.value}
														minEntries={0}
														maxEntries={5}
														placeholder={translate('Add accepted entry')}
													/>
												</GridItem>
											)}
										/>
									</>
								) : null}
							</Flex>
						</TabPanel>
						<TabPanel>
							<Flex pt={6} alignItems='center'>
								<Switch id='showFloatingLegend' size='lg' {...register('showFloatingLegend')} />
								<FormLabel htmlFor='showFloatingLegend' fontSize='lg' mx={2}>
									{translate('Floating legend')}
								</FormLabel>
							</Flex>
							<Flex pt={2} alignItems='center'>
								<Switch id='showFooterLegend' size='lg' {...register('showFooterLegend')} />
								<FormLabel htmlFor='showFooterLegend' fontSize='lg' mx={2}>
									{translate('Footer legend')}
								</FormLabel>
							</Flex>
							<Box mt={4}>
								<SlideSettingsInput presentationId={presentationId} slide={visualization.slide} />
							</Box>
						</TabPanel>
						<TabPanel>
							<Flex flexDirection='row' alignItems='center'>
								<Flex flexDirection='column' alignItems='center'>
									{/* <Text fontSize="lg">Variant</Text> */}
									<Stack pr={4}>
										<Text mt={4} fontSize='lg'>
											Style
										</Text>
										<Controller
											name='variant'
											control={control}
											rules={{ required: true }}
											render={({ field: { ...field } }) => (
												<RadioButtonGroup defaultValue='left' size='xl' {...field}>
													<RadioButton value='PIE'>Pie</RadioButton>
													<RadioButton value='DONUT'>Donut</RadioButton>
												</RadioButtonGroup>
											)}
										/>
									</Stack>
								</Flex>
							</Flex>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</form>
		</>
	);
};
