import ApiClient from '../../../apiClient';
import { BasicPresentationT } from '../../../types/Presentation.ts';
import { InteractionT, InteractionTypeT } from '../../../types/Interaction.ts';
import { Entry } from '../model/entry.ts';

export interface EntryListResponse {
	entries: Entry[];
	interaction: BasicInteraction;
}

interface BasicInteraction {
	title: string;
	type: InteractionTypeT;
}

export interface SearchEntriesInput {
	query: string;
	pageNumber: number;
}

export const searchEntries = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: SearchEntriesInput): Promise<EntryListResponse> => {
	return (
		await ApiClient.getInstance().get<EntryListResponse>(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`, {
			params: {
				q: input.query,
				pageNumber: input.pageNumber,
			},
		})
	).data;
};

// ------

export interface ModerateEntryInput {
	entryId: number;
	hidden: boolean;
}

export const moderateEntry = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], input: ModerateEntryInput): Promise<void> => {
	await ApiClient.getInstance().post(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`, input);
};

// ------
export const deleteEntriesByInteraction = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id']): Promise<void> => {
	await ApiClient.getInstance().delete(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries`);
};

// ------
export const exportEntriesToCsv = async (presentationId: BasicPresentationT['id'], interactionId: InteractionT['id'], filename: string = 'entries.csv'): Promise<void> => {
	await ApiClient.getInstance()
		.get(`/author-app/presentations/${presentationId}/interactions/${interactionId}/entries/export/csv`)
		.then((response) => {
			// Create a Blob from the response data
			const fileBlob = new Blob([response.data as string], { type: 'text/csv' });

			// Generate a temporary URL for the file
			const fileUrl = window.URL.createObjectURL(fileBlob);

			// Create a link element
			const link = document.createElement('a');
			link.href = fileUrl;

			// Suggest a filename (you can also parse Content-Disposition header if available)
			link.setAttribute('download', filename);

			// Append to the DOM and trigger click
			document.body.appendChild(link);
			link.click();

			// Clean up
			document.body.removeChild(link);
			window.URL.revokeObjectURL(fileUrl);
		})
		.catch((error) => {
			console.error('Download failed:', error);
		});
};
