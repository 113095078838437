import { InteractionTypeT } from '../types/Interaction';
import { ElementTypeT } from '../types/Presentation';
import { VisualizationTypeT } from '../types/Visualization';
import get from 'lodash/get';
import { StaticContentTypeT } from '../types/StaticContent.ts';
import { translate } from '../translate';

export const mapElementTypeToWords = (type?: InteractionTypeT | VisualizationTypeT | StaticContentTypeT): string => {
	const map: Record<string, string> = {
		[InteractionTypeT.NUMBER]: translate('Number'),
		[InteractionTypeT.OPEN_ENDED]: translate('Short answers'),
		[InteractionTypeT.OPEN_ENDED_LONG]: translate('Long answers'),
		[InteractionTypeT.CATEGORISED]: translate('Categorized'),
		[InteractionTypeT.VALUATION]: translate('Valuation'),
		[InteractionTypeT.IMAGE]: translate('Image'),
		[InteractionTypeT.ICONIC]: translate('Iconic'),
		[InteractionTypeT.DRAWING]: translate('Drawing'),
		[InteractionTypeT.MULTIPLE_CHOICE]: translate('Multiple Choice'),
		[InteractionTypeT.LINK]: translate('Link'),
		[VisualizationTypeT.ROTATING_WORDS]: translate('Rotating Words'),
		[VisualizationTypeT.WORD_CLOUD]: translate('Word Cloud'),
		[VisualizationTypeT.BAR_CHART]: translate('Bar Chart'),
		[VisualizationTypeT.PIE_CHART]: translate('Pie Chart'),
		[VisualizationTypeT.GAUGE]: translate('Gauge'),
		[VisualizationTypeT.RADAR]: translate('Radar'),
		[VisualizationTypeT.PICTOGRAM]: translate('Pictogram'),
		[VisualizationTypeT.WHEEL]: translate('Wheel'),
		[VisualizationTypeT.NODES]: translate('Nodes'),
		[VisualizationTypeT.BLOCKS]: translate('Blocks'),
		[VisualizationTypeT.BASIC_CARDS]: translate('Cards'),
		[VisualizationTypeT.BASIC_FLIP_CARDS]: translate('Flip Cards'),
		[VisualizationTypeT.COMIC]: translate('Comic'),
		[VisualizationTypeT.SIMPLE_MATH]: translate('Simple Math'),
		[VisualizationTypeT.BOARD]: translate('Board'),
		[VisualizationTypeT.PILLS]: translate('Pills'),
		[StaticContentTypeT.COVER]: translate('Cover'),
		[StaticContentTypeT.EMBED]: translate('Embed'),
		[StaticContentTypeT.STATEMENT]: translate('Statement'),
		[StaticContentTypeT.QUOTE]: translate('Quote'),
		[StaticContentTypeT.LIST]: translate('List'),
		[StaticContentTypeT.CALL_TO_ACTION]: translate('Call to action'),
	};

	return type ? get(map, type, '') : '';
};

// type IconComponent = React.ComponentType<React.SVGProps<SVGSVGElement>>;

type IconMap = {
	[key in ElementTypeT]: {
		[key: string]: string;
	};
};

const internalElementToIconMap: IconMap = {
	[ElementTypeT.INTERACTION]: {
		[InteractionTypeT.NUMBER]: '/assets/brand/icons/NUMBER.svg',
		[InteractionTypeT.OPEN_ENDED]: '/assets/brand/icons/OPEN_ENDED.svg',
		[InteractionTypeT.OPEN_ENDED_LONG]: '/assets/brand/icons/OPEN_ENDED_LONG.svg',
		[InteractionTypeT.IMAGE]: '/assets/brand/icons/IMAGE.svg',
		[InteractionTypeT.ICONIC]: '/assets/brand/icons/ICONIC.svg',
		[InteractionTypeT.DRAWING]: '/assets/brand/icons/DRAWING.svg',
		[InteractionTypeT.MULTIPLE_CHOICE]: '/assets/brand/icons/MULTIPLE_CHOICE.svg',
		[InteractionTypeT.CATEGORISED]: '/assets/brand/icons/CATEGORIZED.svg',
		[InteractionTypeT.VALUATION]: '/assets/brand/icons/VALUATION.svg',
		[InteractionTypeT.LINK]: '/assets/brand/icons/LINK.svg',
	},
	[ElementTypeT.VISUALIZATION]: {
		[VisualizationTypeT.COMIC]: '/assets/brand/icons/COMIC.svg',
		[VisualizationTypeT.ROTATING_WORDS]: '/assets/brand/icons/ROTATING_WORDS.svg',
		[VisualizationTypeT.WORD_CLOUD]: '/assets/brand/icons/WORD_CLOUD.svg',
		[VisualizationTypeT.BAR_CHART]: '/assets/brand/icons/BAR_CHART.svg',
		[VisualizationTypeT.PIE_CHART]: '/assets/brand/icons/PIE_CHART.svg',
		[VisualizationTypeT.WHEEL]: '/assets/brand/icons/WHEEL.svg',
		[VisualizationTypeT.GAUGE]: '/assets/brand/icons/GAUGE.svg',
		[VisualizationTypeT.RADAR]: '/assets/brand/icons/RADAR.svg',
		[VisualizationTypeT.PICTOGRAM]: '/assets/brand/icons/PICTOGRAM.svg',
		[VisualizationTypeT.NODES]: '/assets/brand/icons/NODES.svg',
		[VisualizationTypeT.BLOCKS]: '/assets/brand/icons/BLOCKS.svg',
		[VisualizationTypeT.SIMPLE_MATH]: '/assets/brand/icons/SIMPLE_MATH.svg',
		[VisualizationTypeT.BASIC_CARDS]: '/assets/brand/icons/BASIC_CARDS.svg',
		[VisualizationTypeT.BASIC_FLIP_CARDS]: '/assets/brand/icons/BASIC_FLIP_CARDS.svg',
		[VisualizationTypeT.BOARD]: '/assets/brand/icons/BOARD.svg',
		[VisualizationTypeT.PILLS]: '/assets/brand/icons/PILLS.svg',
	},
	[ElementTypeT.STATIC_CONTENT]: {
		[StaticContentTypeT.COVER]: '/assets/brand/icons/COVER.svg',
		[StaticContentTypeT.EMBED]: '/assets/brand/icons/EMBED.svg',
		[StaticContentTypeT.STATEMENT]: '/assets/brand/icons/STATEMENT.svg',
		[StaticContentTypeT.QUOTE]: '/assets/brand/icons/QUOTE.svg',
		[StaticContentTypeT.LIST]: '/assets/brand/icons/LIST.svg',
		[StaticContentTypeT.CALL_TO_ACTION]: '/assets/brand/icons/CALL_TO_ACTION.svg',
	},
};

export const getIconForElement = (elementType: ElementTypeT) => (actualType: InteractionTypeT | VisualizationTypeT | StaticContentTypeT) => {
	const element = get(internalElementToIconMap, elementType);
	return get(element, actualType);
};
