import React from 'react';
import { getClientAppPresentationUrl } from '../../service/LinkService.ts';
import {
	Button,
	Divider,
	Flex,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	useClipboard,
	useDisclosure,
} from '@chakra-ui/react';
import { translate } from '../../../../translate';
import { BasicPresentationT } from '../../../../types/Presentation.ts';
import { MdShare } from 'react-icons/md';

interface Props {
	presentationId: BasicPresentationT['id'];
	presentationTitle: BasicPresentationT['title'];
}

export const EmbedPresentation: React.FC<Props> = ({ presentationId, presentationTitle }) => {
	const presentationUrl = getClientAppPresentationUrl(presentationId);
	const iframeString: string = `<iframe title='${presentationTitle}' src='${presentationUrl}' width='100%' height='750'></iframe>`;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const { onCopy, hasCopied } = useClipboard(iframeString);
	const { onCopy: onCopyUrl, hasCopied: hasCopiedUrl } = useClipboard(presentationUrl);

	return (
		<>
			<Button rightIcon={<MdShare />} variant='ghost' size='lg' onClick={onOpen} px={5}>
				{translate('Share')}
			</Button>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{translate('Share')}</ModalHeader>
					<ModalCloseButton />
					<ModalBody mb={6}>
						<Flex flexDirection='column' gap={6}>
							<Flex flexDirection='column' gap={2}>
								<Text fontWeight='bold'>{translate('Link')}</Text>
								<InputGroup size='md'>
									<Input disabled pr='4.5rem' variant='outline' textColor='grey' value={presentationUrl} />
									<InputRightElement width='4.5rem'>
										<Button h='1.75rem' size='sm' variant='ghost' onClick={onCopyUrl}>
											{hasCopiedUrl ? 'Copied' : 'Copy'}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Flex>
							<Divider />
							<Flex flexDirection='column' gap={2}>
								<Text fontWeight='bold'>{translate('Embed')}</Text>
								<InputGroup size='md'>
									<Input disabled pr='4.5rem' variant='outline' textColor='grey' value={iframeString} />
									<InputRightElement width='4.5rem'>
										<Button h='1.75rem' size='sm' variant='ghost' onClick={onCopy}>
											{hasCopied ? 'Copied' : 'Copy'}
										</Button>
									</InputRightElement>
								</InputGroup>
							</Flex>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
};
